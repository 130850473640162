<template>
  <div>
    <label class="label">Email list</label>
    <v-form ref="form" @submit.prevent="createEmailGroup" style="display: flex;justify-content: center;align-items: center;margin-bottom: 15px;">
      <div style="flex:5;padding-right: 15px;">
        <v-text-field
            outlined
            :rules="nameRules"
            v-model="createName"
            hide-details
            placeholder="Type list name"
            dense
        ></v-text-field>
      </div>
      <div style="flex:1;">
        <v-btn class="object__submit btn btn_primary"
               style="float:right;"
               color="primary"
               outlined
               small
               solo
               type="submit"
        >
          Create list
        </v-btn>
      </div>
    </v-form>
    <div style="margin-bottom: 15px;">
      <v-data-table class="table"
                    :headers="headers"
                    :items="items"
                    hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
          <tr v-for="item in items" :key="item.id">
            <td class="d-block d-sm-table-cell" style="text-align: center;">{{item.name}}</td>
            <td class="d-block d-sm-table-cell" style="text-align: center;">{{item.listing_emails.length}}</td>
            <td class="d-block d-sm-table-cell" style="text-align: center;">{{formatDate(item.created_at)}}</td>
            <td class="d-block d-sm-table-cell" style="text-align: center;">
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark outlined small
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="cursor:pointer;">
                    <json-csv :data="item.listing_emails" :name="item.name+`.csv`">
                      <v-list-item-title>Download</v-list-item-title>
                    </json-csv>
                  </v-list-item>
                  <v-list-item style="cursor:pointer;">
                    <v-list-item-title @click="showEmailList(item.listing_emails)">Show</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title style="cursor:pointer;color:red;" @click="deleteListShow(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
          <tbody v-if="items.length === 0">
          <tr>
            <td class="d-block d-sm-table-cell" colspan="5" style="text-align: center;padding:0 15px;font-weight: normal;">You don't have lists yet, it's time to create them</td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <PopupDelete ref="PopupDelete" @confirm="deleteList()" :title="`Are you sure about delete?`"/>
    <v-dialog max-width="410px"
              v-model="dialog">
      <div class="popup__content popup__content_success">
            <v-icon class="popup__close"  @click="exit">mdi-close</v-icon>
          <h5 class="popup__title">Emails</h5>
          <v-list min-height="20px">
            <v-list-item v-for="item in selectedList" :key="item">
              <v-list-item-content>
                <v-list-item-title>{{item.email}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SubscribeNotifications",
  components:{
    PopupDelete:()=>import("@/components/Popup/PopupDelete"),
    JsonCsv:()=>import('vue-json-csv')
  },
  data(){
    return{
      nameRules: [(v) => !!v || 'email list name is required'],
      createName:'',
      items:[],
      headers:[
        { text: 'Name', value: "name", align: 'center',sortable:true, },
        { text: 'Emails', value:"listing_emails",align:'center',sortable: true},
        { text: 'Created', value: "created_at", align: 'center',sortable:true, },
        { text: 'Options', value: "user_id", align: 'center',sortable:false, },
      ],
      deleteId:null,
      selectedList:[],
      dialog:false
    }
  },
  methods:{
    showEmailList(list){
      this.dialog = true;
      this.selectedList = list
    },
    exit(){
      this.dialog = false
      this.selectedList = [];
    },
    formatDate(date){
      return this.$moment(date).format('DD.MM.yyyy HH:mm')
    },
    async createEmailGroup(){
      if(this.$refs.form.validate()){
        let data = {
          hotel_id:this.$route.params.id,
          name:this.createName
        }
        const response = await this.$axios.post(process.env.VUE_APP_API_URL+'listing',data);
        response.data.listing_emails = [];
        this.items.push(response.data)
      }
    },
    deleteListShow(id){
      this.deleteId = id;
      this.$refs.PopupDelete.open();
    },
    async deleteList(){
      try{
        await this.$axios.delete(`${process.env.VUE_APP_API_URL}listing/${this.deleteId}`);
        const candidate = this.items.find(x => x.id === this.deleteId);
        if(candidate){
          this.items.splice(this.items.indexOf(candidate),1);
        }
      }catch (e){
console.log(e)
      }
    }
  },
  async mounted() {
    try{
      const response = await this.$axios.get(process.env.VUE_APP_API_URL+'listing?hotel_id='+this.$route.params.id);
      this.items = response.data;
    } catch (e) {
      console.log(e);
    }
  }
}
</script>

<style scoped>

</style>